/* eslint no-console:0 */
import 'trix/dist/trix.css'

require('trix')
require('@rails/actiontext')

import Vue from 'vue/dist/vue';

window.Vue = Vue;

Vue.config.productionTip = false;
Vue.config.devtools = true;

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
Vue.component('vue-typeahead', VueBootstrapTypeahead);

import ClubsFinder from '../js/components/clubs_finder';


import axios from 'axios';
Vue.prototype.$axios = axios;

document.addEventListener('DOMContentLoaded', () => {
  let vm = new Vue({
    el: '#menu',
    components: {
      'clubs-finder': ClubsFinder
    }
  });
});