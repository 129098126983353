<template>
  <vue-typeahead
    v-model="query"
    :maxMatches="10"
    :minMatchingChars="3"
    :data="variants"
    :serializer="(s) => s.name + ' ' + s.city"
    @hit="hit($event)"
    placeholder="название клуба или город"
  >
    <template slot="suggestion" slot-scope="{ data }">
      {{ data.name }} / {{ data.city}}
    </template>
  </vue-typeahead>
</template>

<script>
import _ from 'underscore';

export default {
  name: 'debtor-finder',
  data: function () {
    return {
      query: null,
      variants: [],
    };
  },
  methods: {
    hit: function (selected) {
      document.location.href = '/clubs/'+selected.slug
    },
    load: function (query) {
      this.$axios.get('/api/clubs/find?query=' + query).then((res) => {
        this.variants = res.data;
      });
    },
  },
  watch: {
    query: _.debounce(function (query) {
      this.load(query);
    }, 300),
  },
};
</script>
